






























import { IBook } from '@/models/book';
import { AccountStore } from '@/store/modules';
import { getToken } from '@/utils';
import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'BookCard',
  props: {
    book: {
      type: Object as PropType<IBook>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const categoryNames = computed(() =>
      props.book.categories.map(category => category.nm).join(', '),
    );

    function handleClickBookMark(marked: boolean) {
      if (!AccountStore.userId || !getToken()) {
        alert('로그인 후 이용해 주세요');
        return;
      }

      emit('bookmark', marked);
    }

    return {
      categoryNames,

      handleClickBookMark,
    };
  },
});
