






























import { ILearnPack } from '@/models';
import { AccountStore } from '@/store/modules';
import { getToken } from '@/utils';
import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'LearnPackCard',
  props: {
    data: {
      type: Object as PropType<ILearnPack>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const titleColor = computed(() => {
      const { index } = props;
      const colorList = ['#61afef', '#E5C07B', '#C678DD', '#E06C75', '#98C379', '#E5C07B'];
      return colorList[index % 6];
    });

    function handleClickBookMark(marked: boolean) {
      if (!AccountStore.userId || !getToken()) {
        alert('로그인 후 이용해 주세요');
        return;
      }

      emit('bookmark', marked);
    }

    return {
      titleColor,

      handleClickBookMark,
    };
  },
});
