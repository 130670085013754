

































import { ICategory } from '@/models';
import { RountName } from '@/router';
import { LEARNMOA_TYPE } from '@/utils';
import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'CategorySideBar',
  props: {
    data: {
      type: Array as PropType<ICategory[]>,
      required: true,
    },
    learnmoaType: {
      type: String as PropType<LEARNMOA_TYPE>,
      required: true,
    },
  },
  setup(_, { root }) {
    const queryCategory = computed(() => root.$route.query.category ?? '');

    return {
      queryCategory,
      RountName,
      LEARNMOA_TYPE,
    };
  },
});
