














































import { imageUploadService } from '@/services';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'InputBox',
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      required: true,
    },
  },
  setup(_, { emit }) {
    function onInput(e: Event) {
      const { value } = e.target as HTMLInputElement;
      emit('update:value', value);
    }

    async function onInputFile(e: Event) {
      const { files } = e.target as HTMLInputElement;

      try {
        const response = await imageUploadService.uploadOne(files as FileList);
        emit('update:value', response.url);
      } catch (error) {
        alert('등록할 수 없는 파일입니다.');
      }
    }

    return {
      onInput,
      onInputFile,
    };
  },
});
