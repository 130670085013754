







































import { ILecture } from '@/models';
import { computed, defineComponent, PropType, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'SearchLectureCard',
  props: {
    thumbnail: {
      type: String as PropType<ILecture['lectureImg']>,
      required: true,
    },
    name: {
      type: String as PropType<ILecture['lectureName']>,
      required: true,
    },
    origin: {
      type: String as PropType<ILecture['lectureOrigin']['nm']>,
      required: true,
    },
    teacher: {
      type: String as PropType<ILecture['lectureTeacher']>,
      required: true,
    },
    price: {
      type: Number as PropType<ILecture['lecturePrice']>,
      required: true,
    },
    originalPrice: {
      type: Number as PropType<ILecture['lectureOriginalPrice']>,
      required: true,
    },
    tags: {
      type: Array as PropType<ILecture['tags']>,
      required: true,
    },
    duration: {
      type: Number as PropType<ILecture['lectureDuration']>,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const isSelected = ref(false);

    const tagList = computed(() => {
      const tags = props.tags.slice(0, 3);

      return [...new Set(tags)];
    });

    const computedDuration = computed(() => {
      // -1 알수없음
      // 0
      if (props.duration === -1 || props.duration === 0) {
        return '총 - 시간';
      }

      const HOUR_SEC = 3600;
      const hour = (props.duration / HOUR_SEC).toFixed(1);

      return `총 ${hour}시간`;
    });

    function formattingPrice(price: number) {
      if (price > 0) {
        return `${price.toLocaleString()}원`;
      }

      return '무료';
    }

    function handleClickCard() {
      if (!props.selectable && !isSelected.value) {
        alert('1개의 강의만 선택할 수 있습니다.');
        return;
      }

      isSelected.value = !isSelected.value;
      emit('select');
    }

    return {
      isSelected,

      computedDuration,
      tagList,
      formattingPrice,

      handleClickCard,
    };
  },
});
