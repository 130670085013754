
























import { computed, defineComponent, ref, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'DropDown',
  props: {
    options: {
      type: Array as PropType<{ nm: string; val: string }[]>,
      required: true,
    },
    selectedValue: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const dropDownBoxRef = ref<HTMLDivElement>();

    const isShowOptions = ref(false);

    const selectedOptionName = computed(
      () => props.options.find(type => type.val === props.selectedValue)?.nm,
    );

    const dropDownBoxWidth = computed(() => {
      if (!props.selectedValue) {
        return 100;
      }

      return dropDownBoxRef.value?.offsetWidth;
    });

    function isSelected(value: string) {
      return value === props.selectedValue;
    }

    function toggleOptions() {
      isShowOptions.value = !isShowOptions.value;
    }

    function onShowOptionsClicked() {
      toggleOptions();
    }

    function onValueClicked(value: string) {
      toggleOptions();

      emit('select', value);
    }

    return {
      dropDownBoxRef,

      isShowOptions,

      selectedOptionName,
      dropDownBoxWidth,

      isSelected,

      onValueClicked,
      onShowOptionsClicked,
    };
  },
});
