
























import '../../../node_modules/@fortawesome/fontawesome-free/css/all.css';
import '../../../node_modules/vue-ads-pagination/dist/vue-ads-pagination.css';

import { computed, defineComponent, toRefs } from '@vue/composition-api';

import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination';

export default defineComponent({
  name: 'Pagination',
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 30,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
  },
  components: {
    VueAdsPagination,
    VueAdsPageButton,
  },
  setup(props, { emit }) {
    const { page } = toRefs(props);

    // pagination 컴포넌트가 시작이 0부터라 props page로부터 1을 빼줘야함
    const paginationPage = computed(() => page.value - 1);

    function pageChange(p: number) {
      // 1 빼준걸 다시 더해 줘야 인덱스가 맞음
      emit('update:page', p + 1);
      emit('change-page');
    }

    return {
      paginationPage,
      pageChange,
    };
  },
});
