

























import { IRecommendKeyword } from '@/models';
import { FILTER_KEY_TYPE, propSync } from '@/utils';
import { defineComponent, PropType } from '@vue/composition-api';
import { SearchRecommend } from './components';

export default defineComponent({
  name: 'SearchBar',
  props: {
    searchKey: {
      type: String,
      required: true,
    },
    recommendKeywords: {
      type: Array as PropType<IRecommendKeyword[]>,
      default: null,
    },
    selectedRecommendKeyword: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  components: {
    SearchRecommend,
  },
  setup(props, { emit }) {
    const syncedSearchKey = propSync(props, 'searchKey');

    function onSearchEnter() {
      emit('submit');
    }

    function onClickDeleteSearchKey() {
      syncedSearchKey.value = '';

      emit('submit');
    }

    function onSelectRecommendKeyword(value: string) {
      emit('select-recommend-keyword', value);
    }

    return {
      FILTER_KEY_TYPE,

      syncedSearchKey,

      onSearchEnter,
      onClickDeleteSearchKey,
      onSelectRecommendKeyword,
    };
  },
});
