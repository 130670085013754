



import { defineComponent, onMounted, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'Obeserver',
  setup(_, { emit }) {
    const observerRef = ref();

    const options = {
      root: null,
      threshold: 0.1,
    };

    function handleIntersect(entry: IntersectionObserverEntry) {
      if (entry.isIntersecting) {
        emit('intersect');
      }
    }

    onMounted(() => {
      const observer = new IntersectionObserver(entries => {
        handleIntersect(entries[0]);
      }, options);
      observer.observe(observerRef.value);
    });

    return {
      observerRef,
    };
  },
});
