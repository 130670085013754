







































































import { ILecture } from '@/models';
import { AccountModule, AccountStore } from '@/store/modules';
import { getToken } from '@/utils';
import { computed, defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'LectureCard',
  props: {
    thumbnail: {
      type: String as PropType<ILecture['lectureImg']>,
      required: true,
    },
    name: {
      type: String as PropType<ILecture['lectureName']>,
      required: true,
    },
    origin: {
      type: String as PropType<ILecture['lectureOrigin']['nm']>,
      required: true,
    },
    teacher: {
      type: String as PropType<ILecture['lectureTeacher']>,
      required: true,
    },
    price: {
      type: Number as PropType<ILecture['lecturePrice']>,
      required: true,
    },
    originalPrice: {
      type: Number as PropType<ILecture['lectureOriginalPrice']>,
      required: true,
    },
    tags: {
      type: Array as PropType<ILecture['tags']>,
      required: true,
    },
    duration: {
      type: Number as PropType<ILecture['lectureDuration']>,
      required: true,
    },
    term: {
      type: Number as PropType<ILecture['lectureTerm']>,
      required: true,
    },
    amount: {
      type: Number as PropType<ILecture['lectureAmount']>,
      required: true,
    },
    level: {
      type: String as PropType<ILecture['lectureLevel']['nm']>,
      required: true,
    },
    categories: {
      type: Array as PropType<ILecture['categories']>,
      required: true,
    },
    bookMarked: {
      type: Boolean as PropType<ILecture['bookMarked']>,
      required: true,
    },
    bookMarkId: {
      type: Number as PropType<ILecture['bookMarkId']>,
      default: null,
    },
  },
  setup(props, { emit }) {
    const isMobile = computed(() => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent));

    const tagList = computed(() => {
      const tags = props.tags.slice(0, 3);

      return [...new Set(tags)];
    });

    const computedTerm = computed(() => {
      // -1 알수없음
      // 0  무제한
      if (props.term === -1) {
        return ' - 일';
      }

      if (props.term === 0) {
        return '무제한';
      }

      return `${props.term}일`;
    });

    const computedDuration = computed(() => {
      // -1 알수없음
      // 0
      if (props.duration === -1 || props.duration === 0) {
        return '총 - 시간';
      }

      const HOUR_SEC = 3600;
      const hour = (props.duration / HOUR_SEC).toFixed(1);

      return `총 ${hour}시간`;
    });

    const computedAmount = computed(() => {
      // -1 알수없음
      // 0
      if (props.amount === -1 || props.amount === 0) {
        return ' - 개';
      }

      return `${props.amount}개`;
    });

    const category = computed(() => props.categories.map(cate => cate.nm).join(', '));

    function formattingPrice(price: number) {
      if (price > 0) {
        return `${price.toLocaleString()}원`;
      }

      return '무료';
    }

    function handleClickBookMark(marked: boolean) {
      if (!AccountStore.userId || !getToken()) {
        alert('로그인 후 이용해 주세요');
        return;
      }
      emit('bookmark', marked);
    }

    return {
      isMobile,

      tagList,
      computedTerm,
      computedDuration,
      computedAmount,
      category,

      formattingPrice,

      handleClickBookMark,
    };
  },
});
