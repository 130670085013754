




































import { RountName } from '@/router';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Footer',
  setup() {
    return {
      RountName,
    };
  },
});
