
























import { IBook } from '@/models/book';

import { computed, defineComponent, PropType, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'BookCard',
  props: {
    thumbnail: {
      type: String as PropType<IBook['bookImg']>,
      required: true,
    },
    name: {
      type: String as PropType<IBook['bookName']>,
      required: true,
    },
    subName: {
      type: String as PropType<IBook['bookSubName']>,
      default: null,
    },
    author: {
      type: String as PropType<IBook['bookAuthor']>,
      required: true,
    },
    publisher: {
      type: String as PropType<IBook['bookPublisher']>,
      required: true,
    },
    originalPrice: {
      type: Number as PropType<IBook['bookOriginalPrice']>,
      required: true,
    },
    categoryList: {
      type: Array as PropType<IBook['categories']>,
      required: true,
    },
    amount: {
      type: Number as PropType<IBook['bookAmount']>,
      required: true,
    },
    tags: {
      type: Array as PropType<IBook['tags']>,
      required: true,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const isSelected = ref(false);

    const categoryNames = computed(() =>
      props.categoryList.map(category => category.nm).join(', '),
    );

    function handleClickCard() {
      if (!props.selectable && !isSelected.value) {
        alert('1개의 도서만 선택할 수 있습니다.');
        return;
      }

      isSelected.value = !isSelected.value;
      emit('select');
    }

    return {
      isSelected,

      categoryNames,

      handleClickCard,
    };
  },
});
