





















import { IRecommendKeyword } from '@/models';

import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'SearchRecommend',
  props: {
    recommendKeywords: {
      type: Array as PropType<IRecommendKeyword[]>,
      default: null,
    },
    selectedRecommendKeyword: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    function onKeywordClick(value: string) {
      if (props.selectedRecommendKeyword === value) {
        emit('select', '');
        return;
      }
      emit('select', value);
    }

    return {
      onKeywordClick,
    };
  },
});
