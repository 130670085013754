



























































import { RountName } from '@/router';
import { AccountStore } from '@/store/modules';
import { useDevice } from '@/utils';
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NavigationBar',
  setup(_, { root }) {
    const { isMobile } = useDevice();

    const userMetaInfo = computed(() => AccountStore.info);

    function onUserInfoClicked() {
      root.$router.push({ name: RountName.Mypage });
    }

    return {
      RountName,

      userMetaInfo,

      isMobile,

      onUserInfoClicked,
    };
  },
});
