<template>
  <div class="learn-pack-card-skeleton">
    <div class="learn-pack-card-skeleton__thumbnail">
      <vue-skeleton-loader width="100%" height="100%" :rounded="true"></vue-skeleton-loader>
    </div>

    <div style="display: flex; align-items: center; column-gap: 5px">
      <vue-skeleton-loader :width="30" :height="30" type="circle"></vue-skeleton-loader>

      <vue-skeleton-loader :width="50" :height="20" :rounded="true"></vue-skeleton-loader>
    </div>

    <div style="display: flex; align-items: center; column-gap: 5px">
      <vue-skeleton-loader :width="25" :height="15" :rounded="true"></vue-skeleton-loader>
      <vue-skeleton-loader :width="25" :height="15" :rounded="true"></vue-skeleton-loader>
      <vue-skeleton-loader :width="25" :height="15" :rounded="true"></vue-skeleton-loader>
    </div>

    <div>
      <vue-skeleton-loader :width="100" :height="15" :rounded="true"></vue-skeleton-loader>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import VueSkeletonLoader from 'skeleton-loader-vue';

export default defineComponent({
  name: 'SkeletonLoader',
  components: {
    VueSkeletonLoader,
  },
});
</script>

<style lang="scss" scoped>
.learn-pack-card-skeleton {
  // width: 100%;

  margin: 0;
  padding: 0;

  & > div {
    margin-top: 5px;

    &:first-child {
      width: 100% !important;
    }
  }

  &__thumbnail {
    position: relative;
    padding-top: 70%;

    > div {
      position: absolute;
      top: 0;
    }
  }
}
</style>
