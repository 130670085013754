































import { propSync } from '@/utils';
import { defineComponent, PropType, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'TagInputBox',
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    tags: {
      type: Array as PropType<string[]>,
      required: true,
    },
    limit: {
      type: Number,
    },
  },
  setup(props) {
    const inputValue = ref('');

    const syncedTags = propSync(props, 'tags');

    function onEnter(e: Event) {
      const { value } = e.target as HTMLInputElement;

      if (!value.trim()) return;

      if (syncedTags.value.includes(value)) {
        alert('이미 존재하는 값입니다.');
        return;
      }

      if (props.limit && syncedTags.value.length >= props.limit) {
        alert(`최대 ${props.limit}개까지 등록할 수 있습니다`);
        return;
      }

      syncedTags.value = [...syncedTags.value, value];
      inputValue.value = '';
    }

    function handleClickDeleteTag(index: number) {
      syncedTags.value.splice(index, 1);
    }

    return {
      inputValue,

      onEnter,
      handleClickDeleteTag,
    };
  },
});
