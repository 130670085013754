






























































































































































import { IBook, ILearnPackExternalLink, ILecture } from '@/models';
import { learnPackSearvice } from '@/services';
import { LEARNPACK_CONTENT_TYPE, propSync } from '@/utils';
import { computed, defineComponent, PropType, reactive, ref } from '@vue/composition-api';
import { Observer, InputBox } from '@/components';
import { SearchLectureCard, SearchBookCard } from './components';
import LoadingSpinner from '../LoadingSpinner/index.vue';

export default defineComponent({
  name: 'SearchModal',
  props: {
    contentType: {
      type: String as PropType<LEARNPACK_CONTENT_TYPE | null>,
      default: null,
    },
  },
  components: {
    SearchLectureCard,
    SearchBookCard,
    Observer,
    InputBox,
    LoadingSpinner,
  },
  setup(props, { emit }) {
    const syncedContentType = propSync(props, 'contentType');

    const isLoading = ref(false);

    const lectureList = ref<ILecture[]>([]);
    const bookList = ref<IBook[]>([]);
    const externalLink = ref('');
    const keyword = ref('');

    const selectedLectureList = ref<ILecture[]>([]);
    const selectedBookList = ref<IBook[]>([]);
    const externalLinkData = reactive<ILearnPackExternalLink>({
      thumbnail: '',
      link: '',
      origin: '',
      title: '',
      description: '',
    });
    const comment = ref('');

    const selectedContentCount = ref(0);

    const page = ref(1);
    const lastPage = ref(false);

    const title = computed(() => {
      if (props.contentType === LEARNPACK_CONTENT_TYPE.Lecture) {
        return '강의 등록하기';
      }
      if (props.contentType === LEARNPACK_CONTENT_TYPE.Book) {
        return '도서 등록하기';
      }
      if (props.contentType === LEARNPACK_CONTENT_TYPE.ExternalLink) {
        return '링크 등록하기';
      }
      return '등록하기';
    });

    const isValid = computed(() => {
      if (props.contentType === LEARNPACK_CONTENT_TYPE.Lecture) {
        return selectedLectureList.value.length > 0;
      }
      if (props.contentType === LEARNPACK_CONTENT_TYPE.Book) {
        return selectedBookList.value.length > 0;
      }
      if (props.contentType === LEARNPACK_CONTENT_TYPE.ExternalLink) {
        return !!externalLinkData.title;
      }

      return false;
    });

    async function fetchData() {
      if (isLoading.value) return;

      if (!keyword.value) return;

      if (!syncedContentType.value) return;

      isLoading.value = true;

      try {
        const response = await learnPackSearvice.search({
          page: page.value,
          keyword: keyword.value,
          contentType: syncedContentType.value,
        });

        if (syncedContentType.value === LEARNPACK_CONTENT_TYPE.Lecture) {
          lectureList.value = [...lectureList.value, ...response.content];
        }
        if (syncedContentType.value === LEARNPACK_CONTENT_TYPE.Book) {
          bookList.value = [...bookList.value, ...response.content];
        }

        lastPage.value = response.last;

        if (!response.last) {
          page.value += 1;
        }
      } catch {
        //
      } finally {
        isLoading.value = false;
      }
    }

    function checkUrl(url: string) {
      const expUrl = /^http[s]?:\/\/([\S]{3,})/i;
      return expUrl.test(url);
    }

    async function getOpenGraph() {
      if (isLoading.value) return;

      if (!externalLink.value) return;

      if (!checkUrl(externalLink.value)) {
        alert('url 형식에 맞지 않습니다');
        return;
      }

      isLoading.value = true;

      try {
        const response = await learnPackSearvice.openGraph(externalLink.value);

        externalLinkData.thumbnail =
          response.thumbnail || 'https://learnmoa.com/img/learnmoa-description.png';
        externalLinkData.origin = response.origin;
        externalLinkData.link = response.link;
        externalLinkData.title = response.title;
        externalLinkData.description = response.description;
      } catch {
        //
      } finally {
        isLoading.value = false;
      }
    }

    function handleClickSearhExternalLink() {
      getOpenGraph();
    }

    function handleIntersect() {
      fetchData();
    }

    function handleEnterKeyword() {
      page.value = 1;
      lastPage.value = false;

      if (syncedContentType.value === LEARNPACK_CONTENT_TYPE.Lecture) {
        lectureList.value = [];
        selectedLectureList.value = [];
      }
      if (syncedContentType.value === LEARNPACK_CONTENT_TYPE.Book) {
        bookList.value = [];
        selectedBookList.value = [];
      }

      selectedContentCount.value = 0;

      fetchData();
    }

    function handleClickLecture(selectedLecture: ILecture) {
      const findedIndex = selectedLectureList.value.findIndex(
        lecture => lecture.lectureId === selectedLecture.lectureId,
      );

      if (findedIndex === -1) {
        selectedLectureList.value.push(selectedLecture);
        selectedContentCount.value = 1;
        return;
      }

      selectedLectureList.value.splice(findedIndex, 1);
      selectedContentCount.value = 0;
    }

    function handleClickBook(selectedBook: IBook) {
      const findedIndex = selectedBookList.value.findIndex(
        book => book.bookId === selectedBook.bookId,
      );

      if (findedIndex === -1) {
        selectedBookList.value.push(selectedBook);
        selectedContentCount.value = 1;
        return;
      }

      selectedBookList.value.splice(findedIndex, 1);
      selectedContentCount.value = 0;
    }

    function handleClickClose() {
      // null처리하면 모달 닫힘
      syncedContentType.value = null;
      selectedContentCount.value = 0;
    }

    function handleClickAdd() {
      if (syncedContentType.value === LEARNPACK_CONTENT_TYPE.Lecture) {
        emit('add', LEARNPACK_CONTENT_TYPE.Lecture, selectedLectureList.value, comment.value);
      }

      if (syncedContentType.value === LEARNPACK_CONTENT_TYPE.Book) {
        emit('add', LEARNPACK_CONTENT_TYPE.Book, selectedBookList.value, comment.value);
      }

      if (syncedContentType.value === LEARNPACK_CONTENT_TYPE.ExternalLink) {
        emit('add', LEARNPACK_CONTENT_TYPE.ExternalLink, externalLinkData, comment.value);
      }

      syncedContentType.value = null;
    }

    return {
      LEARNPACK_CONTENT_TYPE,

      title,

      lectureList,
      bookList,
      externalLinkData,

      externalLink,
      keyword,
      comment,

      selectedContentCount,

      isLoading,
      lastPage,

      isValid,

      handleEnterKeyword,
      handleIntersect,
      handleClickLecture,
      handleClickBook,
      handleClickSearhExternalLink,
      handleClickClose,
      handleClickAdd,
    };
  },
});
