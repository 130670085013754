<template>
  <div class="book-card-skeleton">
    <div class="book-card-skeleton__thumbnail">
      <vue-skeleton-loader :width="80" :height="100" :rounded="true"></vue-skeleton-loader>
    </div>

    <div class="book-card-skeleton__content">
      <div class="book-card-skeleton__name">
        <vue-skeleton-loader :width="150" :height="15" :rounded="true"></vue-skeleton-loader>
      </div>

      <div class="book-card-skeleton__sub-name">
        <vue-skeleton-loader :width="120" :height="12" :rounded="true"></vue-skeleton-loader>
      </div>

      <div>
        <vue-skeleton-loader :width="100" :height="10" :rounded="true"></vue-skeleton-loader>
      </div>

      <div>
        <vue-skeleton-loader :width="70" :height="10" :rounded="true"></vue-skeleton-loader>
      </div>

      <div>
        <vue-skeleton-loader :width="100" :height="10" :rounded="true"></vue-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import VueSkeletonLoader from 'skeleton-loader-vue';

export default defineComponent({
  name: 'BookCardSkeleton',
  components: {
    VueSkeletonLoader,
  },
});
</script>

<style lang="scss" scoped>
.book-card-skeleton {
  width: 100%;

  display: flex;
  align-items: center;

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 0 20px;

    & > div {
      width: 100%;

      margin-top: 5px;
    }
  }

  &__name {
    > div {
      width: 80% !important;
    }
  }

  &__sub-name {
    > div {
      width: 60% !important;
    }
  }
}
</style>
